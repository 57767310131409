/* .loginList {
  border: 1px solid red !important;
} */

.login_option {
  border: 0.5px solid var(--hb-light-primary) !important;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  text-align: left;
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  position: relative;
}

.login_option:hover {
  background: var(--hb-light-primary);
  color: white;
}
.login_option:hover .option_img_wrapper:before {
  border: 3px solid white;
  z-index: 1;
}

.login_option img {
  width: 50px;
  height: 50px;
  border: 2px solid var(--hb-light-primary) !important;
  border-radius: 50%;
  padding: 2px;
}
.login_option:hover .option_img {
  border: 2px solid white !important;
}
.option_img_wrapper:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 3.5px;
  left: 15px;
  right: 0px;
  border: 2px solid Var(--hb-light-primary);
  width: 67px;
  height: 67px;
  border-radius: 50%;
}

.avatar_and_name {
  display: flex;
  align-items: center;
  gap: 25px;
}

.avatar_and_name p {
  font-weight: bold;
}
